// 文件名：src/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

i18n
  .use(Backend) // 使用后端服务来加载语言资源
  .use(LanguageDetector) // 自动检测用户语言
  .use(initReactI18next) // 连接 React 和 i18next
  .init({
    fallbackLng: 'en', // 当检测不到用户语言时使用的默认语言
    debug: true, // 在开发环境中启用调试模式
    interpolation: {
      escapeValue: false, // React 已经默认保护 XSS
    },
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json', // 语言文件的路径
    },
  });

export default i18n;
