import React from 'react';
// 导入 React，这是一个用于构建用户界面的 JavaScript 库。

import ReactDOM from 'react-dom/client';
// 导入 ReactDOM，用于将 React 组件渲染到页面的 DOM 节点中。

import './index.css';
// 导入全局 CSS 文件，应用于整个项目的样式。

import reportWebVitals from './reportWebVitals';
// 导入性能监测工具 reportWebVitals，用于记录和报告应用的性能指标。

import './i18n'; // 导入 i18n 配置
// 导入 i18n 配置文件，为应用程序提供国际化支持。

import 'bootstrap/dist/css/bootstrap.min.css';
// 导入 Bootstrap 的 CSS 文件，应用于全局的样式和布局。
// ！如果需要扩展bootstrap组件，必须要把这一项放在引用页面项的前面，以至于不会被覆盖。

import App from './App';
// 导入 App 组件，这是整个应用的主组件。

const root = ReactDOM.createRoot(document.getElementById('root'));
// 创建一个 React 根渲染器，将应用挂载到 DOM 中 id 为 'root' 的元素上。

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
// 使用 React.StrictMode 渲染 App 组件，React.StrictMode 是一个工具，用于突出显示应用程序中潜在问题的帮助程序，在开发模式下对代码执行额外检查。

// If you want to start measuring performance in your app, pass a function
// 如果你想开始测量应用的性能，可以传递一个函数

// to log results (for example: reportWebVitals(console.log))
// 用于记录结果（例如：传递 console.log 给 reportWebVitals）

// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// 或者将结果发送到分析端点。了解更多：https://bit.ly/CRA-vitals

reportWebVitals();
// 调用 reportWebVitals 函数来测量和报告应用的性能指标（例如：页面加载时间等）。
