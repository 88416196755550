import React, { useState, useEffect } from "react";
import "./LightSpotCursor.css"; // 外部CSS文件

const SpotLightCursor = () => {
  const [position, setPosition] = useState({ x: 0, y: 0 });

  // 处理鼠标移动事件
  const handleMouseMove = (event) => {
    setPosition({ x: event.pageX, y: event.pageY });
  };

  useEffect(() => {
    document.addEventListener("mousemove", handleMouseMove);

    // 清理事件监听器
    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  return (
    <div
      className="light-spot-cursor"
      style={{ left: `${position.x}px`, top: `${position.y}px` }}
    />
  );
};

export default SpotLightCursor;
