import React, { useState } from "react";
import "./App.css";
import { useTranslation } from "react-i18next";
// import PaperPlane from "./components/PaperPlane/PaperPlane.jsx"
import LightSpotCursor from "./components/LightSpotCursor/LightSpotCursor.jsx"; // 引入自定义鼠标组件

// import FloatingButton from './components/FloatingButton/FloatingButton.jsx';

// import logo from "./assets/logo/logo_void_backgound.png";

function App() {
  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  }

  return (
    <div className="container-fluid">
      {/* <FloatingButton /> */}
      {/* <PaperPlane /> */}
      <LightSpotCursor /> {/* 添加自定义鼠标组件 */}
      <div className="row header-row">
        <div className="row header-navbar-row">
          <div className="nav-logo col-md-4">
            {/* <img src={logo} className="App-logo" alt="logo" /> */}
            <h1 className="logo">{t("logo")}</h1>
          </div>
          <div className="nav-lang-btn col-md-3">
            <button
              className="btn btn-primary lang-btn"
              onClick={() => changeLanguage("en")}
            >
              English
            </button>
            <button
              className="btn btn-primary lang-btn"
              onClick={() => changeLanguage("zh")}
            >
              简体中文
            </button>
          </div>
        </div>
        <div className="row header-main-row">
          <h1>{t("welcome")}</h1>
          <div className="line"></div>
          <h2>{t("welcome_2")}</h2>
          <button id="enter" className="btn btn-primary pinky-btn">
          {t("start")}
          </button>
        </div>
        <div className="clouds">
          <img
            src="/image/cloud/cloud1.png"
            style={{ "--i": 1 }}
            alt="cloud1"
          />
          <img
            src="/image/cloud/cloud2.png"
            style={{ "--i": 2 }}
            alt="cloud2"
          />
          <img
            src="/image/cloud/cloud3.png"
            style={{ "--i": 3 }}
            alt="cloud3"
          />
          <img
            src="/image/cloud/cloud4.png"
            style={{ "--i": 4 }}
            alt="cloud4"
          />
          <img
            src="/image/cloud/cloud5.png"
            style={{ "--i": 5 }}
            alt="cloud5"
          />
          <img
            src="/image/cloud/cloud1.png"
            style={{ "--i": 10 }}
            alt="cloud1"
          />
          <img
            src="/image/cloud/cloud2.png"
            style={{ "--i": 9 }}
            alt="cloud2"
          />
          <img
            src="/image/cloud/cloud3.png"
            style={{ "--i": 8 }}
            alt="cloud3"
          />
          <img
            src="/image/cloud/cloud4.png"
            style={{ "--i": 7 }}
            alt="cloud4"
          />
          <img
            src="/image/cloud/cloud5.png"
            style={{ "--i": 6 }}
            alt="cloud5"
          />
        </div>
      </div>
      {/* <div className="row content-row">
        <h3>There are moments when life feels off balance, even on calm days, and especially during darker times. This led me to search for a better way to manage my life. Sometimes, I wish things could stay just as they are, or that I could simply let go, but I realized that a better approach could make it easier to maintain that balance. After many trials, I understood that no single efficiency tool or collection of them could bring meaningful change. What I needed was a holistic solution. But first, I had to accept my life as it is—whether it meets my expectations or not. Only then could I begin building a better environment, a system that helps me access the right resources and opportunities with ease.

        Lifera is inspired by the best practices from real-life experiences and the shared wisdom of our community. It's not just another productivity tool or a set of features—it's a complete system designed to help you organize and elevate every aspect of your life. Enlightened by the collective wisdom of shared experiences, you can craft a lifestyle that reflects your individuality while contributing to a beautiful, shared life with others. Lifera helps you focus on what truly matters and creates the foundation for a balanced, fulfilling life.

        有时，生活看似平静，却让我感到失衡，尤其是在情绪低落的时刻。这促使我开始寻找一种更好的方式来管理生活。有时，我希望生活能保持现状，或者顺其自然，但我意识到，采用更好的方法，可以让维持这种平衡变得更加轻松。经过多次努力后，我明白了，仅靠一个或多个效率工具，无法带来真正的改变。我需要的是一个全面的解决方案。但首先，我必须接受现在的生活，无论它是否符合我的期望。只有这样，我才能开始构建一个更好的环境——一个帮助我轻松获取适量资源和机会的系统。

        Lifera 的灵感源自真实生活经验的最佳实践，以及我们社区成员共享的智慧。它不仅仅是一个生产力工具或一组功能，而是一个帮助你组织并提升生活各方面的完整系统。在共同经验的智慧启发下，你可以设计出属于自己的独特生活方式，同时与他人一起，共同创造美好生活。Lifera 让你专注于真正重要的事，为你打造平衡而充实的生活奠定基础。
</h3>
      </div>
      <div className="row footer-row">
        <p>
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Assumenda
          vero aliquid ma
        </p>
        <p>Copyright 2020 Outdoor Dreamyland</p>
      </div> */}
    </div>
  );
}

export default App;
